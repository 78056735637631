/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import * as React from 'react'
import {
    PrismicPreviewProvider,
    componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import "./src/styles/montserrat.css"
// import "./src/styles/cc-fonts.css"
// import "./src/styles/gotham.css"
import "./src/styles/halcom.css"

// import './src/styles/reset.css'
// import './src/styles/common.css'
// import './src/styles/style.css'

import { linkResolver } from './src/utils/linkResolver'

export const wrapRootElement = ({ element }) => (
    <PrismicPreviewProvider
        repositoryConfigs={[
            {
                repositoryName: 'claritycoat',
                linkResolver,
            },
        ]}
    >
        {element}
    </PrismicPreviewProvider>
)