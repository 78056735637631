const { defaultLanguage } = require('../../prismic-configuration')

exports.linkResolver = (doc) => {
    // URL for a category type
    if (doc.type === 'blog_post') {
        return doc.lang === defaultLanguage ? `/blogpost/${doc.uid}` : `/${doc.lang}/blogpost/${doc.uid}`;
    }

    if (doc.type === 'podcast_episode') {
        return doc.lang === defaultLanguage ? `/podcast/${doc.uid}/` : `/${doc.lang}/podcast/${doc.uid}/`
    }

    if (doc.type === 'podcast') {
        return doc.lang === defaultLanguage ? `/podcast/` : `/${doc.lang}/podcast/`
    }

    if (doc.type === 'locations') {
        return doc.lang === defaultLanguage ? `/find-an-installer/` : `/${doc.lang}/find-an-installer/`
    }

    if (doc.type === 'home') {
        return doc.lang === defaultLanguage  ? `/` : `/${doc.lang}/`
    }

    if (doc.type === 'fullpage_sliderno_navigation') {
        return doc.lang === defaultLanguage ? `/${doc.uid}/` : `/${doc.lang}/${doc.uid}/`
    }

    if (doc.type === 'fullpage_slider') {
        return doc.lang === defaultLanguage ? `/${doc.uid}/` : `/${doc.lang}/${doc.uid}/`
    }

    if (doc.type === 'generic_page') {
        return doc.lang === defaultLanguage ? `/${doc.uid}/` : `/${doc.lang}/${doc.uid}/`
    }

    if (doc.type === 'content_page') {
        return doc.lang === defaultLanguage ? `/${doc.uid}/` : `/${doc.lang}/${doc.uid}/`
    }

    // Backup for all other types
    return '/'
}