/**
 * This file is used for setting up and connecting to Prismic.
 */

module.exports = {
  // The default language for content in your Prismic repository.
  defaultLanguage: 'en-us',

  // All available languages for content in your Prismic repository.
  langs: {
    'United States': [
      {
        code: 'en-us',
        country: 'United States',
        name: 'English',
      }
    ],
    'Europe': [
      {
        code: 'en-gb',
        country: 'United Kingdom',
        name: 'English',
      },

      {
        code: 'ro',
        country: 'Romania',
        name: 'Romanian',
      }
    ],
  },

  locationsDefault: {
    'en-us': {
      zoom: 3,
      initialCenter: {
        lat: 44.5040502,
        lng: -98.2730204
      },
    },
    'en-gb': {
      zoom: 3,
      initialCenter: {
        lat: 44.5040502,
        lng: -98.2730204
      },
    },
    'ro': {
      zoom: 6,
      initialCenter: {
        lat: 45.9443,
        lng: 25.0094
      }
    }
  }
};